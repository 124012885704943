import axios from 'axios'
const CancelToken = axios.CancelToken

//const clientApi = axios.create({
//  baseURL: 'http://localhost:5001',
//})
const clientApi = axios.create({
  baseURL: 'https://admin.cloudki.io',
})

const token = {
  set(token) {
    clientApi.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  unset() {
    clientApi.defaults.headers.common.Authorization = ''
  },
}

/* AUTHORIZATION */

// auth

const singUp = body => clientApi.post('/api/auth/signup', body)

const singIn = body => clientApi.post('/api/auth/signin', body)

const switchAccount = body => clientApi.post('/api/auth/switchAccount', body)

const recoverPassord = email => clientApi.post('/api/auth/recover', { email })

const verifyRecoverPassword = code => clientApi.get(`/api/auth/recover/${code}`)

const setNewPassord = (password, code) =>
  clientApi.post(`/api/auth/recover/${code}`, { password })

const getCurrent = () => clientApi.get('/api/auth/current')

const logOut = () => clientApi.post('/api/auth/logout')

// user

const editUserInfo = newInfo => clientApi.patch(`/api/user`, newInfo)

const getLicenses = () => clientApi.get('/api/user/licenses')

const sendToSupport = body => clientApi.post(`/api/user/support`, body)

const getPlans = () => clientApi.get('/api/billing/plan')

const getProducts = () => clientApi.get('/api/billing/product')

const getCurrentPlan = stripeId => clientApi.get(`/api/user/plan/${stripeId}`)

const getLicenseProducts = () => clientApi.get('/api/billing/productlicense')

const getAllPlansInfo = async stripeId => {
  const result = await Promise.all([
    getPlans(),
    getProducts(),
    getCurrentPlan(stripeId),
    getLicenseProducts()
  ])
  return {
    plans: result[0].data,
    products: result[1].data,
    currentSubscription: result[2].data.currentSubscription,
    licenseproducts: result[3].data,
  }
}

// settings
const getStripeInfo = stripeId =>
  clientApi.get(`/api/settings/stripe/${stripeId}`)

const getReskinInfo = () => clientApi.get(`/api/settings/reskin`)

const updateReskinInfo = reskin => {
  clientApi.post(`/api/settings/reskin`, reskin)
}

const getSettingsInfo = async stripeId => {
  const result = await Promise.all([getStripeInfo(stripeId), getReskinInfo()])
  return { stripeInfo: result[0].data, reskinInfo: result[1].data.reskin }
}

const updateStripeInfo = (stripeId, body) =>
  clientApi.post(`/api/settings/stripe/${stripeId}`, body)

const updatePaymentInfo = (stripeId, body) =>
  clientApi.post(`/api/settings/stripe/payment/${stripeId}`, body)



const addTrainingVideo = (data) => 
  clientApi.post(`/api/training-videos`,data)

const getAllTrainingVideos = ()=>
  clientApi.get(`/api/training-videos`)

const updateTrainingVideo = (id,data) =>
  clientApi.put(`/api/training-videos/${id}`,data)

const deleteTrainingVideo = (id) => 
  clientApi.delete(`/api/training-videos/${id}`)

const training = {
  addTrainingVideo,
  getAllTrainingVideos,
  deleteTrainingVideo,
  updateTrainingVideo
}


const auth = {
  singUp,
  singIn,
  switchAccount,
  recoverPassord,
  verifyRecoverPassword,
  setNewPassord,
  getCurrent,
  sendToSupport,
  logOut,
  editUserInfo,
  getLicenses,
  getAllPlansInfo,
  getSettingsInfo,
  updateStripeInfo,
  updateReskinInfo,
  updatePaymentInfo,
}

/* TABLE DATA */

// all table data

let cancelPrevApps
const cancelMessage = 'Operation canceled due to new request.'
const cancelPrevAppsRequest = () => {
  if (typeof cancelPrevApps != typeof undefined) {
    cancelPrevApps.cancel(cancelMessage)
  }
  cancelPrevApps = CancelToken.source()
}

const getFiltered = filter =>
  clientApi.post('/api/admin/tableData', filter, {
    cancelToken: cancelPrevApps.token,
  })

const getAllForDownload = table => clientApi.get(`/api/apps/${table}`)

// licenses
const addLicense = newLicense => clientApi.post('/api/license', newLicense)

const updateLicense = body =>
  clientApi.post('/api/license/update', body);

// users
const updateLicenseCount = (userId, count) => clientApi.patch('/api/subpartners', {userId, count});

// upgradeLicensesPlan(name, plan)
const upgradeLicensesPlan = (id, plan) =>
  clientApi.patch('/api/license', { plan, id })

const changeLicensesStatus = (status, licenses) =>
  clientApi.post('/api/license/status', { status, licenses })

const deleteLicenses = arrLicenses => {
  const hash = arrLicenses.reduce((acc, id) => (acc += `${id}&`), '')
  return clientApi.delete(`/api/license?${hash}`)
}

// sub-partners
const addSubPartner = newSubPartner =>
  clientApi.post('/api/subpartners', newSubPartner)

const updateSubPartnersLicenses = body =>
  clientApi.patch('/api/subpartners', body)

const updateSubpartnersCount = (userId, count) =>
  clientApi.patch('/api/subpartners/updateSubpartners', {userId, count})

const changeSubPartnrsStatus = (status, subPartners) =>
  clientApi.post('/api/subpartners/status', { status, subPartners })

const deleteSubPartners = arrUsersId => {
  const hash = arrUsersId.reduce((acc, id) => (acc += `${id}&`), '')
  return clientApi.delete(`/api/subpartners?${hash}`)
}

const updateUser = body => 
  clientApi.post('/api/subpartners/update', body);

const overrideUserPlan = (userId, plan) => clientApi.post('/api/subpartners/overridePlan', {userId, planName: plan})

const changeReskin = (userId, reskin) => clientApi.post('/api/subpartners/changeReskin', {userId, reskin})

const changeEngageAIEnabled = (userId, enabled) => clientApi.post('/api/subpartners/changeEngageAI', {userId, enabled})

const table = {
  updateUser,
  overrideUserPlan,
  changeReskin,
  changeEngageAIEnabled,
  getFiltered,
  cancelMessage,
  cancelPrevAppsRequest,
  getAllForDownload,
  updateLicenseCount,
  addLicense,
  updateLicense,
  addSubPartner,
  changeLicensesStatus,
  upgradeLicensesPlan,
  deleteLicenses,
  updateSubPartnersLicenses,
  updateSubpartnersCount,
  changeSubPartnrsStatus,
  deleteSubPartners,
}

/* SUBSCRIPTION */

const create = (userid, body) =>
  clientApi.post(`/api/billing/subscription/${userid}`, body)

const unsubscribe = userid =>
  clientApi.delete(`/api/billing/subscription/${userid}`)

const skip = userid => clientApi.get(`/api/billing/subscription/skip/${userid}`)

const portal = userid => clientApi.get(`/api/billing/subscription/portal/${userid}`)

const subscription = { create, unsubscribe, skip, portal }

/* AFFILATE */

const getStats = () => clientApi.get('/api/admin/stats')

const getAllUsers = () => clientApi.get('/api/admin/getAllUsers');

const getTokenForLoginAs = (userId) => clientApi.get(`/api/admin/getUserToken?Id=${userId}`);

const getResetUrlUser = (userId) => clientApi.get(`/api/admin/getUserRestoreLink?Id=${userId}`);

const getResetUrlLicense = (userId) => clientApi.get(`/api/admin/getLicenseRestoreLink?Id=${userId}`);

// const getResetUrlLicense = (userId) => clientApi.get(`https://admin.cloudki.io/Login/RecoveryLinkLicense?Id=${userId}`);

const admin = { getStats, getAllUsers, getTokenForLoginAs, getResetUrlUser, getResetUrlLicense }



// eslint-disable-next-line
export default {
  token,
  auth,
  table,
  subscription,
  admin,
  training
}
